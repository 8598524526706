
import { experienceModalInfo, experienceModalOpen } from '@/utils/experienceInfo';
import { defineComponent } from 'vue';
import ModalMiniBox from './ModalMiniBox.vue';

export default defineComponent({
    components: {
        ModalMiniBox
    },
    data() {
        return {
            screen: "computer",
            info: experienceModalInfo,
            monitorWidth: "80%",
            monitorMaxWidth: "220px"
        }
    },
    methods: {
        handleClose() {
            experienceModalOpen.value = false;
        },
        resize() {
            window.innerWidth > 700 ? this.screen = "computer" : this.screen = "phone";
        },
        setMonitorWidth() {
            if (this.info.title == 'Garmin' || this.info.title == 'Bosch') {
                if (this.screen == 'computer') {
                    this.monitorWidth = '50%';
                } else {
                    this.monitorWidth = '25% !important';
                    this.monitorMaxWidth = '90px';
                }
            } else if (this.info.title == 'Nestimate' || this.info.title == 'The Daily Nebraskan' || this.info.title == 'brAIn rot' || this.info.title == 'Pi Kappa Alpha') {
                if (this.screen == 'computer') {
                    this.monitorWidth = '85%';
                } else {
                    this.monitorWidth = '75% !important';
                    this.monitorMaxWidth = '260px';
                }
            }
        }
    },
    mounted() {
        this.resize();
        this.setMonitorWidth();
        window.addEventListener('resize', this.resize);
    },
});
